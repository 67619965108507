import { Header } from "./Header";
import { Footer } from "./Footer";

import ConfettiExplosion from "@reonomy/react-confetti-explosion";
import { CSSProperties, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigationAction } from "./business/router/router-actions";
import { getAllProductsAvailable } from "./business/products/ProductsSelector";
import { setAddProduct, setPreAddProduct } from "./business/order/OrderActions";
import { getSelectedProduct } from "./business/order/OrderSelector";
import SimpleImageSlider from "react-simple-image-slider";
import MediaQuery from "react-responsive";
export const LandingPage: React.FC = (props) => {
  var dispatch = useDispatch();
  const delay = 1;
  const source = {
    position: "absolute",
    right: "50%",
    left: "50%",
    bottom: 50,
  } as CSSProperties;
  const [isExploding, setIsExploding] = useState(false);
  const [isExplodingcolored, setIsExplodingcolored] = useState(false);
  const [color, setColor] = useState("");
  const [imageIndex, setImageIndex] = useState(0);

  var available = useSelector(getAllProductsAvailable);
  var inbucket = useSelector(getSelectedProduct);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (imageIndex <= 2) {
        setImageIndex(imageIndex + 1);
      } else {
        setImageIndex(0);
      }
    }, 500);
    return () => {
      clearTimeout(timer1);
    };
  });

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setIsExploding(false);
      if (isExploding) {
        dispatch(navigationAction.open("/zamowienie", { typ: "raw" }));
      }
    }, 500);
    return () => {
      clearTimeout(timer1);
    };
  }, [isExploding]);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setIsExplodingcolored(false);
      if (isExplodingcolored) {
        dispatch(navigationAction.open("/zamowienie", { typ: color }));
      }
    }, 500);
    return () => {
      clearTimeout(timer1);
    };
  }, [isExplodingcolored]);

  const images = [
    { url: "/image/czerwona.png" },
    { url: "/image/srebrna.png" },
    { url: "/image/zlota.png" },
  ];
  return (
    <>
      <Header></Header>
      <main>
        <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
          {/* <h1 className="display-4 fw-normal">Pricing</h1> */}
          <p className="fs-5 text-muted">
            Szanowni Państwo, zapraszamy do zakupu naszych żywych choinek w
            doniczce z rodzinnej plantacji! Oferowane przez nas choinki
            zawierają dowóz w cenie!
            <br /> Poniżej przedstawiamy naszą ofertę:{" "}
          </p>
        </div>

        <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
          {/* <h1 className="display-4 fw-normal">Pricing</h1> */}
          <h3 style={{color: "red", fontWeight: "bold"}}>
            UWAGA!<br /> kupując 4 choinki jednocześnie, 5-ątą otrzymasz gratis! <br/>Dotyczy dostawy pod jeden adres!
          </h3>
        </div>

        <div className="row row-cols-1 row-cols-md-2 mb-2 text-center ">
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm  border-success">
              <div className="card-header py-3  text-white bg-success border-success ">
                <h4 className="my-0">Wersja Nieubrana</h4>
              </div>
              <div className="card-body">
                <img src="/image/raw.png" className="img-fluid" />
                <h1 className="card-title pricing-card-title">
                  99 <small className="text-muted fw-light">PLN</small>
                </h1>
                <br />
                <ul className="list-unstyled mt-3 mb-4">
                  <li>wysokość drzewka do 130 cm</li>
                  <li>(w tym wysokość donicy ok. 20 cm)</li>
                  <li>przyozdobione  czerwoną kokardą na czubku</li>
                  <li>Dowóz na terenie Łodzi </li>
                </ul>
                <br />
                <button
                  onClick={() => {
                    setIsExploding(!isExploding);
                    var position = available.find(
                      (x) => x.name === "choinka nieubrana" && x.qty > 0
                    );
                    if (
                      inbucket.findIndex(
                        (x) => x.productId == position?.productid!
                      ) >= 0
                    ) {
                    } else {
                      dispatch(
                        setPreAddProduct({
                            productId: position?.productid!,
                            productName: position?.name!
                        })
                      );
                    }
                  }}
                  type="button"
                  className="w-100 btn btn-lg btn-success"
                  disabled={
                    !(
                      available.find(
                        (x) => x.name === "choinka nieubrana" && x.qty > 0
                      ) != undefined
                    )
                  }
                >
                  {isExploding && (
                    <div style={source}>
                      <ConfettiExplosion
                        force={0.6}
                        duration={2100}
                        particleCount={300}
                        floorHeight={1600}
                        floorWidth={1600}
                      />
                    </div>
                  )}
                  Kup nieubraną
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm border-success">
              <div className="card-header py-3 text-white bg-success border-success">
                <h4 className="my-0">Wersja Przyozdobiona</h4>
              </div>
              <div className="card-body">
                
            <MediaQuery minWidth={500}>
              <div style={{ maxWidth: "100%", height: "94vh" , border: 0, background: "none"}}>
                  <SimpleImageSlider
                    width="92%"
                    height="94vh"
                    images={images}
                    showBullets={true}
                    showNavs={true}
                    autoPlay={true}
                  />
                </div>    
                 </MediaQuery>
            <MediaQuery maxWidth={500}>
               <div style={{ maxWidth: "100%", height: "68vh" }}>
                  <SimpleImageSlider
                    width="92%"
                    height="68vh"
                    images={images}
                    showBullets={true}
                    showNavs={true}
                    autoPlay={true}
                  />
                </div> 
                </MediaQuery>
                <h1 className="card-title pricing-card-title">
                  159 <small className="text-muted fw-light">PLN</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4 ">
                  <li>
                  wysokość drzewka do 130 cm
                  </li>
                  <li>(w tym wysokość donicy ok. 20 cm)</li>
                  <li>udekorowana wg wybranej wersji kolorystycznej 
                  </li>
                  <li>20-25 bombek plastikowych, łańcuch 2 metry, kokarda na czubku w danej kolorystyce</li>
                  <li>Dowóz na terenie Łodzi</li>
                </ul>
                <div
                  className="btn-group w-100"
                  role="group"
                  aria-label="Basic example"
                >
                  {isExplodingcolored && (
                    <div style={source}>
                      <ConfettiExplosion
                        force={0.6}
                        duration={2100}
                        particleCount={300}
                        floorHeight={1600}
                        floorWidth={1600}
                      />
                    </div>
                  )}
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-warning"
                    onClick={() => {
                      setIsExplodingcolored(!isExplodingcolored);
                      setColor("danger");

                      var position = available.find(
                        (x) => x.name === "choinka złota ubrana" && x.qty > 0
                      );
                      if (
                        inbucket.findIndex(
                          (x) => x.productId == position?.productid!
                        ) >= 0
                      ) {
                      } else {
                        dispatch(
                          setPreAddProduct({
                              productId: position?.productid!,
                              productName: position?.name!
                          })
                        );
                      }
                    }}
                    data-bs-container="body"
                    data-bs-toggle="popover"
                    data-bs-placement="top"
                    data-bs-content="Bottom popover"
                    disabled={
                      !(
                        available.find(
                          (x) => x.name === "choinka złota ubrana" && x.qty > 0
                        ) != undefined
                      )
                    }
                  >
                    Złota
                  </button>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-secondary"
                    onClick={() => {
                      setIsExplodingcolored(!isExplodingcolored);
                      setColor("secondary");

                      var position = available.find(
                        (x) => x.name === "choinka srebrna ubrana" && x.qty > 0
                      );

                      if (
                        inbucket.findIndex(
                          (x) => x.productId == position?.productid!
                        ) >= 0
                      ) {
                      } else {
                        dispatch(
                          setPreAddProduct({
                              productId: position?.productid!,
                              productName: position?.name!
                          })
                        );
                      }
                    }}
                    data-bs-container="body"
                    data-bs-toggle="popover"
                    data-bs-placement="top"
                    data-bs-content="Bottom popover"
                    disabled={
                      !(
                        available.find(
                          (x) =>
                            x.name === "choinka srebrna ubrana" && x.qty > 0
                        ) != undefined
                      )
                    }
                  >
                    Srebrna
                  </button>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-danger"
                    onClick={() => {
                      setIsExplodingcolored(!isExplodingcolored);
                      setColor("danger");

                      var position = available.find(
                        (x) => x.name === "choinka czerwona ubrana" && x.qty > 0
                      );

                      if (
                        inbucket.findIndex(
                          (x) => x.productId == position?.productid!
                        ) >= 0
                      ) {
                      } else {
                        dispatch(
                          setPreAddProduct({
                              productId: position?.productid!,
                              productName: position?.name!
                          })
                        );
                      }
                    }}
                    data-bs-container="body"
                    data-bs-toggle="popover"
                    data-bs-placement="top"
                    data-bs-content="Bottom popover"
                    disabled={
                      !(
                        available.find(
                          (x) =>
                            x.name === "choinka czerwona ubrana" && x.qty > 0
                        ) != undefined
                      )
                    }
                  >
                    Czerwona
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
