import { Header } from "./Header";
import { Footer } from "./Footer";
export const Dostawa: React.FC = (props) => {
  return (
    <>
      <Header></Header>
      <main>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="d-flex justify-content-center">
              <h5>Dostawa</h5>
            </div>
            
            Dostawy do Kupujących będących Konsumentami będą realizowane w godzinach 7:30 - 20:00.
            <ul>
                <li>05.12.2024</li>
                <li>07.12.2024</li>
                <li>14.12.2024</li>
                <li>18.12.2024</li>
                <li>20.12.2024</li>
                <li>21.12.2024</li>
                <li>22.12.2024</li>
                <li>23.12.2024</li>
            </ul>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
